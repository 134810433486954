// --
// Dependencies
import API from "../../data/api-ref";


// --
// Usage section component
const Usage = () => {

    // Toggle Panel
    const togglePanel = ( button, panel_id ) => {
        button.classList.toggle( "active" );

        let panel = document.querySelector( panel_id );

        if ( panel.style.maxHeight ) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    }

    // Render API
    const renderAPI = () => {
        return API.map(( item, index ) => {
            let item_id = item.label.replace( /\s/g, "" ).trim();

            return (
                <div 
                    className="text-color-white" 
                    key={ index } 
                >
                    <button 
                        className="accordion font-heading font-size-2 p-2"
                        onClick={( event ) => togglePanel( event.target, `#panel-${ item_id }` )}
                    >
                        { item.label }
                    </button>

                    <div 
                        id={ `panel-${ item_id }` }
                        className="panel"
                    >
                        <p className="font-body text-color-dark">
                            { item.description }
                        </p>

                        <ul className="text-color-dark">
                            {
                                item.categories.map(( category, index ) => {
                                    return (
                                        <li key={ index }>
                                            { 
                                                category.renderLabel ? 
                                                    <h4 className="font-body-bold">
                                                        { category.label }
                                                    </h4> 
                                                : 
                                                    '' 
                                            }

                                            <ul>
                                                {
                                                    category.options.map(( option, index ) => {
                                                        return (
                                                            <li 
                                                                className="font-body-bold mb-2" 
                                                                key={ index }
                                                            >
                                                                { option.class }

                                                                <ul>
                                                                    {
                                                                        option.results.map(( result, index ) => {
                                                                            return (
                                                                                <li 
                                                                                    className="font-body opacity-5 mt-sm mb-sm"
                                                                                    key={ index }
                                                                                >
                                                                                    { result }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            );
        })
    }

    // Render Component
    return (
        <section 
            id="Usage" 
            className="mt-4"
        >
            <h2 className="font-body-bold font-size-3 text-color-white mb-0">
                Using substrat-util
            </h2>

            <p className="text-color-white font-body font-size-1 mt-1">
                CSS property descriptions taken from <a className="font-body-bold text-color-yellow" href="https://developer.mozilla.org/en-US/" target="_blank" rel="noreferrer">MDN</a>.
            </p>

            { renderAPI() }
        </section>
    );
}


// --
// Export
export default Usage;