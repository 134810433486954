// --
// Dependencies


// --
// Child Components
import InputWrapper from "../partials/InputWrapper";
import CopyTextButton from "../partials/CopyTextButton";


// --
// GettingStarted Section Component
const GettingStarted = () => {
    return (
        <section id="GettingStarted">
            <h2 className="font-body-bold font-size-3">
                Getting Started
            </h2>

            <InputWrapper>
                <span className="font-body display-flex flex-row">
                    npm <strong>install</strong> substrat-util 

                    <textarea 
                        id="CopyText-Install"
                        className="copy-text-container"
                        value="npm install substrat-util" 
                        readOnly
                    ></textarea>

                    <CopyTextButton textarea_id="CopyText-Install" />
                </span>
            </InputWrapper>

            <p className="font-body width-50 font-size-1 text-color-yellow">
                <strong className="font-body-bold">Please note:</strong> substrat-util is a personal project for myself, Michael Wilson, and is not intended for mass user adoption. If you are using this package: that’s awesome! But unforntunately, I am not providing support or taking feature requests at this time. 
            </p>

            <p className="font-body width-70 font-size-2 text-color-white mt-3">
                Once installed, include it anywhere in your project, such as your <strong className="font-body-bold">index.js</strong> or even your <strong className="font-body-bold">.scss</strong> files!
            </p>

            <InputWrapper>
                <span className="font-body display-flex flex-row">
                    <strong>@import</strong> “substrat-util/index.css” 

                    <textarea 
                        id="CopyText-Import"
                        className="copy-text-container"
                        value='@import "substrat-util/index.css"' 
                        readOnly
                    ></textarea>

                    <CopyTextButton textarea_id="CopyText-Import" />
                </span>
            </InputWrapper>
        </section>
    );
}


// --
// Export
export default GettingStarted;
