/*
    {
        label: 'Width',
        description: 'Sets an elements width property.',
        options: [
            {
                class: '.width-10',
                results: [ 'width: 10%' ],
            },
        ]
    }

*/


const spacing = {
    sm: '0.5rem',
    one: '1rem',
    two: '2rem',
    three: '3rem',
    four: '5rem',
    five: '8rem'
}

const curve = {
    one: '3px',
    two: '5px',
    three: '10px',
    four: '25px',
    five: '100%'
}


// API
const API = [
    // Widith start
    {
        label: 'Width',
        description: "The width CSS property sets an element's width. By default, it sets the width of the content area, but if box-sizing is set to border-box, it sets the width of the border area.",
        categories: [
            {
                label: 'Width',
                renderLabel: false,
                options: [
                    {
                        class: '.width-10',
                        results: [ 'width: 10%' ],
                    },
                    {
                        class: '.width-20',
                        results: [ 'width: 20%' ],
                    },
                    {
                        class: '.width-4th',
                        results: [ 'width: 25%' ],
                    },
                    {
                        class: '.width-30',
                        results: [ 'width: 30%' ],
                    },
                    {
                        class: '.width-3rd',
                        results: [ 'width: 33%' ],
                    },
                    {
                        class: '.width-40',
                        results: [ 'width: 40%' ],
                    },
                    {
                        class: '.width-50',
                        results: [ 'width: 50%' ],
                    },
                    {
                        class: '.width-60',
                        results: [ 'width: 60%' ],
                    },
                    {
                        class: '.width-70',
                        results: [ 'width: 70%' ],
                    },
                    {
                        class: '.width-80',
                        results: [ 'width: 80%' ],
                    },
                    {
                        class: '.width-90',
                        results: [ 'width: 90%' ],
                    },
                    {
                        class: '.width-full',
                        results: [
                            'width: 100%',
                            'max-width: 100%',
                            'min-width: 100%'
                        ],
                    },
                    {
                        class: '.width-viewport',
                        results: [ 'width: 100vw', 'min-width: 100vw' ],
                    },
                    {
                        class: '.width-max-content',
                        results: [ 'width: max-content', 'min-width: max-content' ],
                    },
                    {
                        class: '.width-min-content',
                        results: [ 'width: min-content', 'min-width: min-content' ],
                    },
                    {
                        class: '.width-fit-content',
                        results: [ 'width: fit-content', 'min-width: fit-content' ],
                    },
                ]
            }
        ]
        
    },
    // Width end
    // Height start
    {
        label: 'Height',
        description: 'The height CSS property specifies the height of an element. By default, the property defines the height of the content area. If box-sizing is set to border-box, however, it instead determines the height of the border area.',
        categories: [
            {
                label: 'Height',
                renderLabel: false,
                options: [
                    {
                        class: '.height-10',
                        results: [ 'height: 10%', 'min-height: 10%' ],
                    },
                    {
                        class: '.height-20',
                        results: [ 'height: 20%', 'min-height: 20%' ],
                    },
                    {
                        class: '.height-4th',
                        results: [ 'height: 25%', 'min-height: 25%' ],
                    },
                    {
                        class: '.height-30',
                        results: [ 'height: 30%', 'min-height: 30%' ],
                    },
                    {
                        class: '.height-3rd',
                        results: [ 'height: 33%', 'min-height: 33%' ],
                    },
                    {
                        class: '.height-40',
                        results: [ 'height: 40%', 'min-height: 40%' ],
                    },
                    {
                        class: '.height-50',
                        results: [ 'height: 50%', 'min-height: 50%' ],
                    },
                    {
                        class: '.height-60',
                        results: [ 'height: 60%', 'min-height: 60%' ],
                    },
                    {
                        class: '.height70',
                        results: [ 'width: 70%', 'min-height: 70%' ],
                    },
                    {
                        class: '.height-80',
                        results: [ 'height: 80%', 'min-height: 80%' ],
                    },
                    {
                        class: '.height-90',
                        results: [ 'height: 90%', 'min-height: 90%' ],
                    },
                    {
                        class: '.height-full',
                        results: [ 'height: 100%', 'min-height: 100%', 'max-height: 100%' ],
                    },
                    {
                        class: '.height-viewport',
                        results: [ 'height: 100vh', 'min-height: 100vh' ],
                    },
                    {
                        class: '.height-max-content',
                        results: [ 'height: max-content', 'min-height: max-content' ],
                    },
                    {
                        class: '.height-min-content',
                        results: [ 'height: min-content', 'min-height: min-content' ],
                    },
                    {
                        class: '.height-fit-content',
                        results: [ 'height: fit-content', 'min-height: fit-content' ],
                    },
                ]
            }
        ]
    },
    // Height end
    // Object start
    {
        label: 'Object',
        description: "The object-fit CSS property sets how the content of a replaced element, such as an <img> or <video>, should be resized to fit its container. The object-position CSS property specifies the alignment of the selected replaced element's contents within the element's box. Areas of the box which aren't covered by the replaced element's object will show the element's background.",
        categories: [
            // fit
            {
                label: 'Fit',
                renderLabel: true,
                options: [
                    {
                        class: '.object-fit-fill',
                        results: [ 'object-fit: fill' ],
                    },
                    {
                        class: '.object-fit-contain',
                        results: [ 'object-fit: contain' ],
                    },
                    {
                        class: '.object-fit-cover',
                        results: [ 'object-fit: cover' ],
                    },
                    {
                        class: '.object-fit-none',
                        results: [ 'object-fit: none' ],
                    },
                    {
                        class: '.object-fit-scale-down',
                        results: [ 'object-fit: scale-down' ],
                    },
                    {
                        class: '.object-fit-inherit',
                        results: [ 'object-fit: inherit' ],
                    },
                    {
                        class: '.object-fit-initial',
                        results: [ 'object-fit: initial' ],
                    },
                    {
                        class: '.object-fit-revert',
                        results: [ 'object-fit: revert' ],
                    },
                    {
                        class: '.object-fit-revert-layer',
                        results: [ 'object-fit: revert-layer' ],
                    },
                    {
                        class: '.object-fit-unset',
                        results: [ 'object-fit: unset' ],
                    },
                ]
            },
            // Fit end
            // Position
            {
                label: 'Position',
                renderLabel: true,
                options: [
                    {
                        class: '.object-position-top',
                        results: [ 'object-postion: too' ],
                    },
                    {
                        class: '.object-position-bottom',
                        results: [ 'object-postion: bottom' ],
                    },
                    {
                        class: '.object-position-left',
                        results: [ 'object-postion: left' ],
                    },
                    {
                        class: '.object-position-right',
                        results: [ 'object-postion: right' ],
                    },
                    {
                        class: '.object-position-center',
                        results: [ 'object-postion: center' ],
                    },
                    {
                        class: '.object-position-inherit',
                        results: [ 'object-postion: inherit' ],
                    },
                    {
                        class: '.object-position-initial',
                        results: [ 'object-postion: initial' ],
                    },
                    {
                        class: '.object-position-revert',
                        results: [ 'object-postion: revert' ],
                    },
                    {
                        class: '.object-position-revert-layer',
                        results: [ 'object-postion: revert-layer' ],
                    },
                    {
                        class: '.object-position-unset',
                        results: [ 'object-postion: unset' ],
                    },
                ]
            }
            // Position end
        ]
    },
    // Object end
    // Display start
    {
        label: 'Display',
        description: "The display CSS property sets whether an element is treated as a block or inline box and the layout used for its children, such as flow layout, grid or flex.",
        categories: [
            {
                label: 'Display',
                renderLabel: false,
                options: [
                    {
                        class: '.display-none',
                        results: [ 'display: none' ],
                    },
                    {
                        class: '.display-block',
                        results: [ 'display: block' ],
                    },
                    {
                        class: '.display-inline',
                        results: [ 'display: inline' ],
                    },
                    {
                        class: '.display-inline-block',
                        results: [ 'display: inline-block' ],
                    },
                    {
                        class: '.display-grid',
                        results: [ 'display: grid' ],
                    },
                    {
                        class: '.display-inline-grid',
                        results: [ 'display: inline-grid' ],
                    },
                    {
                        class: '.display-flex',
                        results: [ 'display: flex' ],
                    },
                ]
            }
        ]
    },
    // Display end
    // Flex start
    {
        label: 'Flex',
        description: "Flexbox is a one-dimensional layout method for arranging items in rows or columns. Items flex (expand) to fill additional space or shrink to fit into smaller spaces.",
        categories: [
            // Flex Direction
            {
                label: 'Flex Direction',
                renderLabel: true,
                options: [
                    {
                        class: '.flex-row',
                        results: [ 'flex-direction: row' ],
                    },
                    {
                        class: '.flex-row-reverse',
                        results: [ 'flex-direction: row-reverse' ],
                    },
                    {
                        class: '.flex-column',
                        results: [ 'flex-direction: column' ],
                    },
                    {
                        class: '.flex-column-reverse',
                        results: [ 'flex-direction: column-reverse' ],
                    }
                ]
            },
            // Flex Direction end
            // Flex Wrap
            {
                label: 'Flex Wrap',
                renderLabel: true,
                options: [
                    {
                        class: '.flex-wrap',
                        results: [ 'flex-wrap: wrap' ],
                    },
                    {
                        class: '.flex-wrap-reverse',
                        results: [ 'flex-wrap: wrap-reverse' ],
                    },
                    {
                        class: '.flex-nowrap',
                        results: [ 'flex-wrap: nowrap' ],
                    }
                ]
            },
            // Flex Wrap end
            // Flex Gap
            {
                label: 'Flex Gap',
                renderLabel: true,
                options: [
                    {
                        class: '.flex-gap-sm',
                        results: [ `gap: ${ spacing.sm }` ],
                    },
                    {
                        class: '.flex-gap-1',
                        results: [ `gap: ${ spacing.one }` ],
                    },
                    {
                        class: '.flex-gap-2',
                        results: [ `gap: ${ spacing.two }` ],
                    },
                    {
                        class: '.flex-gap-3',
                        results: [ `gap: ${ spacing.three }` ],
                    },
                    {
                        class: '.flex-gap-4',
                        results: [ `gap: ${ spacing.four }` ],
                    },
                    {
                        class: '.flex-gap-5',
                        results: [ `gap: ${ spacing.five }` ],
                    },
                    {
                        class: '.flex-gap-row-sm',
                        results: [ `row-gap: ${ spacing.sm }` ],
                    },
                    {
                        class: '.flex-gap-row-1',
                        results: [ `row-gap: ${ spacing.one }` ],
                    },
                    {
                        class: '.flex-gap-row-2',
                        results: [ `row-gap: ${ spacing.two }` ],
                    },
                    {
                        class: '.flex-gap-row-3',
                        results: [ `row-gap: ${ spacing.three }` ],
                    },
                    {
                        class: '.flex-gap-row-4',
                        results: [ `row-gap: ${ spacing.four }` ],
                    },
                    {
                        class: '.flex-gap-row-5',
                        results: [ `row-gap: ${ spacing.five }` ],
                    },
                    {
                        class: '.flex-gap-column-sm',
                        results: [ `column-gap: ${ spacing.sm }` ],
                    },
                    {
                        class: '.flex-gap-column-1',
                        results: [ `column-gap: ${ spacing.one }` ],
                    },
                    {
                        class: '.flex-gap-column-2',
                        results: [ `column-gap: ${ spacing.two }` ],
                    },
                    {
                        class: '.flex-gap-column-3',
                        results: [ `column-gap: ${ spacing.three }` ],
                    },
                    {
                        class: '.flex-gap-column-4',
                        results: [ `column-gap: ${ spacing.four }` ],
                    },
                    {
                        class: '.flex-gap-column-5',
                        results: [ `column-gap: ${ spacing.five }` ],
                    }
                ]
            },
            // Flex Gap end
            // Justify Content
            {
                label: 'Justify Content',
                renderLabel: true,
                options: [
                    {
                        class: '.justify-content-start',
                        results: [ `justify-content: flex-start` ],
                    },
                    {
                        class: '.justify-content-between',
                        results: [ `justify-content: space-between` ],
                    },
                    {
                        class: '.justify-content-around',
                        results: [ `justify-content: space-around` ],
                    },
                    {
                        class: '.justify-content-space-evenly',
                        results: [ `justify-content: space-evenly` ],
                    },
                    {
                        class: '.justify-content-center',
                        results: [ `justify-content: center` ],
                    },
                    {
                        class: '.justify-content-end',
                        results: [ `justify-content: flex-end` ],
                    }
                ]
            },
            // Justify Content end
            // Align Items
            {
                label: 'Align Items',
                renderLabel: true,
                options: [
                    {
                        class: '.align-items-start',
                        results: [ `align-items: start` ],
                    },
                    {
                        class: '.align-items-center',
                        results: [ `align-items: center` ],
                    },
                    {
                        class: '.align-items-end',
                        results: [ `align-items: end` ],
                    },
                    {
                        class: '.align-items-stretch',
                        results: [ `align-items: stretch` ],
                    },
                    {
                        class: '.align-items-baseline',
                        results: [ `align-items: baseline` ],
                    }
                ]
            },
            // Align Items end
            // Align Content
            {
                label: 'Align Content',
                renderLabel: true,
                options: [
                    {
                        class: '.align-content-start',
                        results: [ `align-content: flex-start` ],
                    },
                    {
                        class: '.align-content-end',
                        results: [ `align-content: flex-end` ],
                    },
                    {
                        class: '.align-content-center',
                        results: [ `align-content: flex-center` ],
                    },
                    {
                        class: '.align-content-space-between',
                        results: [ `align-content: space-between` ],
                    },
                    {
                        class: '.align-content-space-around',
                        results: [ `align-content: space-around` ],
                    },
                    {
                        class: '.align-content-space-evenly',
                        results: [ `align-content: space-evenly` ],
                    },
                    {
                        class: '.align-content-stretch',
                        results: [ `align-content: stretch` ],
                    }
                ]
            },
            // Align Content end
            // Align Self
            {
                label: 'Align Self', 
                renderLabel: true,
                options: [
                    {
                        class: '.align-self-auto',
                        results: [ `align-self: auto` ],
                    },
                    {
                        class: '.align-self-start',
                        results: [ `align-self: flex-start` ],
                    },
                    {
                        class: '.align-self-end',
                        results: [ `align-self: flex-end` ],
                    },
                    {
                        class: '.align-self-center',
                        results: [ `align-self: center` ],
                    },
                    {
                        class: '.align-self-baseline',
                        results: [ `align-self: baseline` ],
                    },
                    {
                        class: '.align-self-stretch',
                        results: [ `align-self: stretch` ],
                    },
                ]
            }
            // Align Self end
        ]
    },
    // Flex end
    // Opacity
    {
        label: 'Opacity',
        description: "The opacity CSS property sets the opacity of an element. Opacity is the degree to which content behind an element is hidden, and is the opposite of transparency.",
        categories: [
            {
                label: 'Opacity',
                renderLabel: false,
                options: [
                    {
                        class: '.opacity-1',
                        results: [ `opacity: 0.1` ],
                    },
                    {
                        class: '.opacity-1-5',
                        results: [ `opacity: 0.15` ],
                    },
                    {
                        class: '.opacity-2',
                        results: [ `opacity: 0.2` ],
                    },
                    {
                        class: '.opacity-2-5',
                        results: [ `opacity: 0.25` ],
                    },
                    {
                        class: '.opacity-3',
                        results: [ `opacity: 0.3` ],
                    },
                    {
                        class: '.opacity-3-5',
                        results: [ `opacity: 0.35` ],
                    },
                    {
                        class: '.opacity-4',
                        results: [ `opacity: 0.4` ],
                    },
                    {
                        class: '.opacity-4-5',
                        results: [ `opacity: 0.45` ],
                    },
                    {
                        class: '.opacity-5',
                        results: [ `opacity: 0.5` ],
                    },
                    {
                        class: '.opacity-5-5',
                        results: [ `opacity: 0.55` ],
                    },
                    {
                        class: '.opacity-6',
                        results: [ `opacity: 0.6` ],
                    },
                    {
                        class: '.opacity-6-5',
                        results: [ `opacity: 0.65` ],
                    },
                    {
                        class: '.opacity-7',
                        results: [ `opacity: 0.7` ],
                    },
                    {
                        class: '.opacity-7-5',
                        results: [ `opacity: 0.75` ],
                    },
                    {
                        class: '.opacity-8',
                        results: [ `opacity: 0.8` ],
                    },
                    {
                        class: '.opacity-8-5',
                        results: [ `opacity: 0.85` ],
                    },
                    {
                        class: '.opacity-9',
                        results: [ `opacity: 0.9` ],
                    },
                    {
                        class: '.opacity-9-5',
                        results: [ `opacity: 0.96` ],
                    },
                    {
                        class: '.opacity-full, .opacity-10, .opacity-100',
                        results: [ `opacity: 1` ],
                    },
                ]
            }
        ]
    },
    // Opacity end
    // Padding
    {
        label: 'Padding',
        description: "The padding CSS property sets the padding area of an element.",
        categories: [
            // All Sides
            {
                label: 'All Sides',
                renderLabel: true,
                options: [
                    {
                        class: '.p-0',
                        results: [ `padding: 0` ],
                    },
                    {
                        class: '.p-sm',
                        results: [ `padding: ${ spacing.sm }` ],
                    },
                    {
                        class: '.p-1',
                        results: [ `padding: ${ spacing.one }` ],
                    },
                    {
                        class: '.p-2',
                        results: [ `padding: ${ spacing.two }` ],
                    },
                    {
                        class: '.p-3',
                        results: [ `padding: ${ spacing.three }` ],
                    },
                    {
                        class: '.p-4',
                        results: [ `padding: ${ spacing.four }` ],
                    },
                    {
                        class: '.p-5',
                        results: [ `padding: ${ spacing.five }` ],
                    },
                ]
            },
            // All Sides end
            // X-Axis
            {
                label: 'X-Axis',
                renderLabel: true,
                options: [
                    {
                        class: '.px-0',
                        results: [ `padding-left: 0`, `padding-right: 0` ],
                    },
                    {
                        class: '.px-sm',
                        results: [ `padding-left: ${ spacing.sm }`, `padding-right: ${ spacing.sm }` ],
                    },
                    {
                        class: '.px-1',
                        results: [ `padding-left: ${ spacing.one }`, `padding-right: ${ spacing.one }` ],
                    },
                    {
                        class: '.px-2',
                        results: [ `padding-left: ${ spacing.two }`, `padding-right: ${ spacing.two }` ],
                    },
                    {
                        class: '.px-3',
                        results: [ `padding-left: ${ spacing.three }`, `padding-right: ${ spacing.three }` ],
                    },
                    {
                        class: '.px-4',
                        results: [ `padding-left: ${ spacing.four }`, `padding-right: ${ spacing.four }` ],
                    },
                    {
                        class: '.px-5',
                        results: [ `padding-left: ${ spacing.five }`, `padding-right: ${ spacing.five }` ],
                    },
                ]
            },
            // X-Axis end
            // Y-Axis
            {
                label: 'Y-Axis',
                renderLabel: true,
                options: [
                    {
                        class: '.py-0',
                        results: [ `padding-top: 0`, `padding-bottom: 0` ],
                    },
                    {
                        class: '.py-sm',
                        results: [ `padding-top: ${ spacing.sm }`, `padding-bottom: ${ spacing.sm }` ],
                    },
                    {
                        class: '.py-1',
                        results: [ `padding-top: ${ spacing.one }`, `padding-bottom: ${ spacing.one }` ],
                    },
                    {
                        class: '.py-2',
                        results: [ `padding-top: ${ spacing.two }`, `padding-bottom: ${ spacing.two }` ],
                    },
                    {
                        class: '.py-3',
                        results: [ `padding-top: ${ spacing.three }`, `padding-bottom: ${ spacing.three }` ],
                    },
                    {
                        class: '.py-4',
                        results: [ `padding-top: ${ spacing.four }`, `padding-bottom: ${ spacing.four }` ],
                    },
                    {
                        class: '.py-5',
                        results: [ `padding-top: ${ spacing.five }`, `padding-bottom: ${ spacing.five }` ],
                    },
                ]
            },
            // Y-Axis end
            // Top
            {
                label: 'Top',
                renderLabel: true,
                options: [
                    {
                        class: '.pt-0',
                        results: [ `padding-top: 0` ],
                    },
                    {
                        class: '.pt-sm',
                        results: [ `padding-top: ${ spacing.sm }` ],
                    },
                    {
                        class: '.pt-1',
                        results: [ `padding-top: ${ spacing.one }` ],
                    },
                    {
                        class: '.pt-2',
                        results: [ `padding-top: ${ spacing.two }` ],
                    },
                    {
                        class: '.pt-3',
                        results: [ `padding-top: ${ spacing.three }` ],
                    },
                    {
                        class: '.pt-4',
                        results: [ `padding-top: ${ spacing.four }` ],
                    },
                    {
                        class: '.pt-5',
                        results: [ `padding-top: ${ spacing.five }` ],
                    },
                ]
            },
            // Top end
            // Bottom
            {
                label: 'Bottom',
                renderLabel: true,
                options: [
                    {
                        class: '.pb-0',
                        results: [ `padding-bottom: 0` ],
                    },
                    {
                        class: '.pb-sm',
                        results: [ `padding-bottom: ${ spacing.sm }` ],
                    },
                    {
                        class: '.pb-1',
                        results: [ `padding-bottom: ${ spacing.one }` ],
                    },
                    {
                        class: '.pb-2',
                        results: [ `padding-bottom: ${ spacing.two }` ],
                    },
                    {
                        class: '.pb-3',
                        results: [ `padding-bottom: ${ spacing.three }` ],
                    },
                    {
                        class: '.pb-4',
                        results: [ `padding-bottom: ${ spacing.four }` ],
                    },
                    {
                        class: '.pb-5',
                        results: [ `padding-bottom: ${ spacing.five }` ],
                    },
                ]
            },
            // Bottom end
            // Left
            {
                label: 'Left',
                renderLabel: true,
                options: [
                    {
                        class: '.pl-0',
                        results: [ `padding-left: 0` ],
                    },
                    {
                        class: '.pl-sm',
                        results: [ `padding-left: ${ spacing.sm }` ],
                    },
                    {
                        class: '.pl-1',
                        results: [ `padding-left: ${ spacing.one }` ],
                    },
                    {
                        class: '.pl-2',
                        results: [ `padding-left: ${ spacing.two }` ],
                    },
                    {
                        class: '.pl-3',
                        results: [ `padding-left: ${ spacing.three }` ],
                    },
                    {
                        class: '.pl-4',
                        results: [ `padding-left: ${ spacing.four }` ],
                    },
                    {
                        class: '.pl-5',
                        results: [ `padding-left: ${ spacing.five }` ],
                    },
                ]
            },
            // Left end
            // Right
            {
                label: 'Right',
                renderLabel: true,
                options: [
                    {
                        class: '.pr-0',
                        results: [ `padding-right: 0` ],
                    },
                    {
                        class: '.pr-sm',
                        results: [ `padding-right: ${ spacing.sm }` ],
                    },
                    {
                        class: '.pr-1',
                        results: [ `padding-right: ${ spacing.one }` ],
                    },
                    {
                        class: '.pr-2',
                        results: [ `padding-right: ${ spacing.two }` ],
                    },
                    {
                        class: '.pr-3',
                        results: [ `padding-right: ${ spacing.three }` ],
                    },
                    {
                        class: '.pr-4',
                        results: [ `padding-right: ${ spacing.four }` ],
                    },
                    {
                        class: '.pr-5',
                        results: [ `padding-right: ${ spacing.five }` ],
                    },
                ]
            },
            // Right end
        ]
    },
    // Padding end
    // Margin
    {
        label: 'Margin',
        description: "The margin CSS property sets the margin area of an element.",
        categories: [
            // All Sides
            {
                label: 'All Sides',
                renderLabel: true,
                options: [
                    {
                        class: '.m-0',
                        results: [ `margin: 0` ],
                    },
                    {
                        class: '.m-sm',
                        results: [ `margin: ${ spacing.sm }` ],
                    },
                    {
                        class: '.m-1',
                        results: [ `margin: ${ spacing.one }` ],
                    },
                    {
                        class: '.m-2',
                        results: [ `margin: ${ spacing.two }` ],
                    },
                    {
                        class: '.m-3',
                        results: [ `margin: ${ spacing.three }` ],
                    },
                    {
                        class: '.m-4',
                        results: [ `margin: ${ spacing.four }` ],
                    },
                    {
                        class: '.m-5',
                        results: [ `margin: ${ spacing.five }` ],
                    },
                ]
            },
            // All Sides end
            // X-Axis
            {
                label: 'X-Axis',
                renderLabel: true,
                options: [
                    {
                        class: '.mx-0',
                        results: [ `margin-left: 0`, `margin-right: 0` ],
                    },
                    {
                        class: '.mx-sm',
                        results: [ `margin-left: ${ spacing.sm }`, `margin-right: ${ spacing.sm }` ],
                    },
                    {
                        class: '.mx-1',
                        results: [ `margin-left: ${ spacing.one }`, `margin-right: ${ spacing.one }` ],
                    },
                    {
                        class: '.mx-2',
                        results: [ `margin-left: ${ spacing.two }`, `margin-right: ${ spacing.two }` ],
                    },
                    {
                        class: '.mx-3',
                        results: [ `margin-left: ${ spacing.three }`, `margin-right: ${ spacing.three }` ],
                    },
                    {
                        class: '.mx-4',
                        results: [ `margin-left: ${ spacing.four }`, `margin-right: ${ spacing.four }` ],
                    },
                    {
                        class: '.mx-5',
                        results: [ `margin-left: ${ spacing.five }`, `margin-right: ${ spacing.five }` ],
                    },
                ]
            },
            // X-Axis end
            // Y-Axis
            {
                label: 'Y-Axis',
                renderLabel: true,
                options: [
                    {
                        class: '.my-0',
                        results: [ `margin-top: 0`, `margin-bottom: 0` ],
                    },
                    {
                        class: '.my-sm',
                        results: [ `margin-top: ${ spacing.sm }`, `margin-bottom: ${ spacing.sm }` ],
                    },
                    {
                        class: '.my-1',
                        results: [ `margin-top: ${ spacing.one }`, `margin-bottom: ${ spacing.one }` ],
                    },
                    {
                        class: '.my-2',
                        results: [ `margin-top: ${ spacing.two }`, `margin-bottom: ${ spacing.two }` ],
                    },
                    {
                        class: '.my-3',
                        results: [ `margin-top: ${ spacing.three }`, `margin-bottom: ${ spacing.three }` ],
                    },
                    {
                        class: '.my-4',
                        results: [ `margin-top: ${ spacing.four }`, `margin-bottom: ${ spacing.four }` ],
                    },
                    {
                        class: '.my-5',
                        results: [ `margin-top: ${ spacing.five }`, `margin-bottom: ${ spacing.five }` ],
                    },
                ]
            },
            // Y-Axis end
            // Top
            {
                label: 'Top',
                renderLabel: true,
                options: [
                    {
                        class: '.mt-0',
                        results: [ `margin-top: 0` ],
                    },
                    {
                        class: '.mt-sm',
                        results: [ `margin-top: ${ spacing.sm }` ],
                    },
                    {
                        class: '.mt-1',
                        results: [ `margin-top: ${ spacing.one }` ],
                    },
                    {
                        class: '.mt-2',
                        results: [ `margin-top: ${ spacing.two }` ],
                    },
                    {
                        class: '.mt-3',
                        results: [ `margin-top: ${ spacing.three }` ],
                    },
                    {
                        class: '.mt-4',
                        results: [ `margin-top: ${ spacing.four }` ],
                    },
                    {
                        class: '.mt-5',
                        results: [ `margin-top: ${ spacing.five }` ],
                    },
                ]
            },
            // Top end
            // Bottom
            {
                label: 'Bottom',
                renderLabel: true,
                options: [
                    {
                        class: '.mb-0',
                        results: [ `margin-bottom: 0` ],
                    },
                    {
                        class: '.mb-sm',
                        results: [ `margin-bottom: ${ spacing.sm }` ],
                    },
                    {
                        class: 'mb-1',
                        results: [ `margin-bottom: ${ spacing.one }` ],
                    },
                    {
                        class: '.mb-2',
                        results: [ `margin-bottom: ${ spacing.two }` ],
                    },
                    {
                        class: '.mb-3',
                        results: [ `margin-bottom: ${ spacing.three }` ],
                    },
                    {
                        class: '.mb-4',
                        results: [ `margin-bottom: ${ spacing.four }` ],
                    },
                    {
                        class: '.mb-5',
                        results: [ `margin-bottom: ${ spacing.five }` ],
                    },
                ]
            },
            // Bottom end
            // Left
            {
                label: 'Left',
                renderLabel: true,
                options: [
                    {
                        class: '.ml-0',
                        results: [ `margin-left: 0` ],
                    },
                    {
                        class: '.ml-sm',
                        results: [ `margin-left: ${ spacing.sm }` ],
                    },
                    {
                        class: '.ml-1',
                        results: [ `margin-left: ${ spacing.one }` ],
                    },
                    {
                        class: '.ml-2',
                        results: [ `margin-left: ${ spacing.two }` ],
                    },
                    {
                        class: '.ml-3',
                        results: [ `margin-left: ${ spacing.three }` ],
                    },
                    {
                        class: '.ml-4',
                        results: [ `margin-left: ${ spacing.four }` ],
                    },
                    {
                        class: '.ml-5',
                        results: [ `margin-left: ${ spacing.five }` ],
                    },
                ]
            },
            // Left end
            // Right
            {
                label: 'Right',
                renderLabel: true,
                options: [
                    {
                        class: '.mr-0',
                        results: [ `margin-right: 0` ],
                    },
                    {
                        class: '.mr-sm',
                        results: [ `margin-right: ${ spacing.sm }` ],
                    },
                    {
                        class: '.mr-1',
                        results: [ `margin-right: ${ spacing.one }` ],
                    },
                    {
                        class: '.mr-2',
                        results: [ `margin-right: ${ spacing.two }` ],
                    },
                    {
                        class: '.mr-3',
                        results: [ `margin-right: ${ spacing.three }` ],
                    },
                    {
                        class: '.mr-4',
                        results: [ `margin-right: ${ spacing.four }` ],
                    },
                    {
                        class: '.mr-5',
                        results: [ `margin-right: ${ spacing.five }` ],
                    },
                ]
            },
            // Right end
        ]
    },
    // Margin end
    // Border Radius
    {
        label: 'Border Radius',
        description: "The border-radius CSS property rounds the corners of an element's outer border edge.",
        categories: [
            // All Corners
            {
                label: 'All Corners',
                renderLabel: true,
                options: [
                    {
                        class: '.border-radius-0',
                        results: [ `border-radius: 0px` ],
                    },
                    {
                        class: '.border-radius-1',
                        results: [ `border-radius: ${ curve.one }` ],
                    },
                    {
                        class: '.border-radius-2',
                        results: [ `border-radius: ${ curve.two }` ],
                    },
                    {
                        class: '.border-radius-3',
                        results: [ `border-radius: ${ curve.three }` ],
                    },
                    {
                        class: '.border-radius-4',
                        results: [ `border-radius: ${ curve.four }` ],
                    },
                    {
                        class: '.border-radius-5',
                        results: [ `border-radius: ${ curve.five }` ],
                    },
                ]
            },
            // All Corners end
            // Top Left
            {
                label: 'Top Left',
                renderLabel: true,
                options: [
                    {
                        class: '.border-radius-tl-0',
                        results: [ `border-top-left-radius: 0px` ],
                    },
                    {
                        class: '.border-radius-tl-1',
                        results: [ `border-top-left-radius: ${ curve.one }` ],
                    },
                    {
                        class: '.border-radius-tl-2',
                        results: [ `border-top-left-radius: ${ curve.two }` ],
                    },
                    {
                        class: '.border-radius-tl-3',
                        results: [ `border-top-left-radius: ${ curve.three }` ],
                    },
                    {
                        class: '.border-radius-tl-4',
                        results: [ `border-top-left-radius: ${ curve.four }` ],
                    },
                    {
                        class: '.border-radius-tl-5',
                        results: [ `border-top-left-radius: ${ curve.five }` ],
                    },
                ]
            },
            // Top Left end
            // Top Right
            {
                label: 'Top Right',
                renderLabel: true,
                options: [
                    {
                        class: '.border-radius-tr-0',
                        results: [ `border-top-right-radius: 0px` ],
                    },
                    {
                        class: '.border-radius-tr-1',
                        results: [ `border-top-right-radius: ${ curve.one }` ],
                    },
                    {
                        class: '.border-radius-tr-2',
                        results: [ `border-top-right-radius: ${ curve.two }` ],
                    },
                    {
                        class: '.border-radius-tr-3',
                        results: [ `border-top-right-radius: ${ curve.three }` ],
                    },
                    {
                        class: '.border-radius-tr-4',
                        results: [ `border-top-right-radius: ${ curve.four }` ],
                    },
                    {
                        class: '.border-radius-tr-5',
                        results: [ `border-top-right-radius: ${ curve.five }` ],
                    },
                ]
            },
            // Top Right end
            // Bottom Right
            {
                label: 'Bottom Right',
                renderLabel: true,
                options: [
                    {
                        class: '.border-radius-br-0',
                        results: [ `border-bottom-right-radius: 0px` ],
                    },
                    {
                        class: '.border-radius-br-1',
                        results: [ `border-bottom-right-radius: ${ curve.one }` ],
                    },
                    {
                        class: '.border-radius-br-2',
                        results: [ `border-bottom-right-radius: ${ curve.two }` ],
                    },
                    {
                        class: '.border-radius-br-3',
                        results: [ `border-bottom-right-radius: ${ curve.three }` ],
                    },
                    {
                        class: '.border-radius-br-4',
                        results: [ `border-bottom-right-radius: ${ curve.four }` ],
                    },
                    {
                        class: '.border-radius-br-5',
                        results: [ `border-bottom-right-radius: ${ curve.five }` ],
                    },
                ]
            },
            // Bottom Right end
            // Bottom Left
            {
                label: 'Bottom Left',
                renderLabel: true,
                options: [
                    {
                        class: '.border-radius-bl-0',
                        results: [ `border-bottom-left-radius: 0px` ],
                    },
                    {
                        class: '.border-radius-bl-1',
                        results: [ `border-bottom-left-radius: ${ curve.one }` ],
                    },
                    {
                        class: '.border-radius-bl-2',
                        results: [ `border-bottom-left-radius: ${ curve.two }` ],
                    },
                    {
                        class: '.border-radius-bl-3',
                        results: [ `border-bottom-left-radius: ${ curve.three }` ],
                    },
                    {
                        class: '.border-radius-bl-4',
                        results: [ `border-bottom-left-radius: ${ curve.four }` ],
                    },
                    {
                        class: '.border-radius-bl-5',
                        results: [ `border-bottom-left-radius: ${ curve.five }` ],
                    },
                ]
            },
            // Bottom Left end
        ]
    },
    // Border Radius end
    // Overflow start
    {
        label: 'Overflow',
        description: "The overflow CSS property sets the desired behavior when content does not fit in the parent element box (overflows) in the horizontal and/or vertical direction.",
        categories: [
            // Overflow Main
            {
                label: 'Overflow',
                renderLabel: false,
                options: [
                    {
                        class: '.overflow-visible',
                        results: [ 'overflow: visible' ],
                    },
                    {
                        class: '.overflow-hidden',
                        results: [ 'overflow: hidden' ],
                    },
                    {
                        class: '.overflow-scroll',
                        results: [ 'overflow: scroll' ],
                    },
                    {
                        class: '.overflow-visible',
                        results: [ 'overflow: auto' ],
                    },
                ]
            },
            // Overflow Main end
            // Y-Axis
            {
                label: 'Y-Axis',
                renderLabel: true,
                options: [
                    {
                        class: '.overflow-y-visible',
                        results: [ 'overflow-y: visible' ],
                    },
                    {
                        class: '.overflow-y-hidden',
                        results: [ 'overflow-y: hidden' ],
                    },
                    {
                        class: '.overflow-y-scroll',
                        results: [ 'overflow-y: scroll' ],
                    },
                    {
                        class: '.overflow-y-visible',
                        results: [ 'overflow-y: auto' ],
                    },
                ]
            },
            // Y-Axis end
            // X-Axis
            {
                label: 'X-Axis',
                renderLabel: true,
                options: [
                    {
                        class: '.overflow-x-visible',
                        results: [ 'overflow-x: visible' ],
                    },
                    {
                        class: '.overflow-x-hidden',
                        results: [ 'overflow-x: hidden' ],
                    },
                    {
                        class: '.overflow-x-scroll',
                        results: [ 'overflow-x: scroll' ],
                    },
                    {
                        class: '.overflow-x-visible',
                        results: [ 'overflow-x: auto' ],
                    },
                ]
            },
            // X-Axis end
        ]
    },
    // Overflow end
];

export default API;