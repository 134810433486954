// --
// Dependencies
import Logo from '../Logo';


// --
// Header Component
const Header = () => {
    return (
        <header className="display-flex flex-row justify-content-between py-2">
            <div className="display-flex flex-column">
                <div className="header-logo display-flex flex-row align-items-center">
                    <Logo 
                        fillColor="#FFF" 
                        height="50"
                        width="50"
                    />

                    <h1 className="display-inline-block font-heading p-0 m-0">
                        substrat-util
                    </h1>
                </div>

                <p className="header-headline font-body">
                    A simple barebones utility class-based CSS library <br />
                    that provides the <strong className="font-body-bold">basics without the bloat</strong>.
                </p>
            </div>

            <div className="display-flex flex-row justify-content-end p-1">
                <a 
                    href="https://ko-fi.com/mikallwilsonn" 
                    className="p-1 border-radius-2 support-button font-body-bold"
                    target="_blank" 
                    rel="noreferrer"
                >
                    Support Me with a Ko-Fi
                </a>
            </div>
        </header>
    );
}


// --
// Export
export default Header;