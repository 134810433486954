// --
// Dependencies
// import react from 'react';


// --
// Child Components
import Header from './sections/Header';
import GettingStarted from './sections/GettingStarted';
import HowItWorks from './sections/HowItWorks';
import Usage from './sections/Usage';
import Footer from './sections/Footer';

// --
// App Component
function App() {
  return (
    <div 
      id="App"
      className="m-auto" 
    >
      <main>
        <Header />

        <GettingStarted />

        <HowItWorks />

        <Usage />

        <Footer />
      </main>
    </div>
  );
}


// --
// Export App
export default App;
