// --
// Depenencies


// --
// CopyTextButton
const CopyTextButton = ({ textarea_id, fillColor = "#FFF" }) => {

    // Copy Text
    const copyText = () => {
        let copyText = document.querySelector( `#${ textarea_id }` );

        copyText.select();
        copyText.setSelectionRange( 0, 99999 ); // For mobile devices

        navigator.clipboard.writeText( copyText.value );

        let notice = document.querySelector( `#${ textarea_id }-copy-notice` );

        notice.style.opacity = 1;

        setTimeout(() => {
            notice.style.opacity = 0;
        }, 3000 );
    }


    // Render Component
    return (
        <>
            <button 
                className="copy-text-button ml-2" 
                onClick={ copyText }
            >
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 448 512"
                >
                    <title>
                        Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.
                    </title>

                    <path 
                        fill={ fillColor }
                        d="M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" 
                    />
                </svg>
            </button>

            <span 
                id={ `${ textarea_id }-copy-notice` }
                className="copy-text-notice"
            >
                Copied!
            </span>
        </>

    );
}


// --
// Export 
export default CopyTextButton;