// --
// Dependencies


// --
// InputWrapper 
const InputWrapper = ({ children }) => {
    return (
        <div className="input-wrapper py-1 px-2 box-shadow">
            { children }
        </div>
    );
}


// --
// Export
export default InputWrapper;
