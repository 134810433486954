// --
// Dependencies


// --
// HowItWorks Section component
const HowItWorks = () => {
    return (
        <section 
            id="HowItWorks" 
            className="mt-4"
        >
            <h2 className="font-body-bold font-size-3 text-color-white">
                How substrat-util works
            </h2>

            <p className="font-body font-size-2 text-color-white width-50">
                substrat-util works as a library meant to quickly provide commonly
                used layout properties. The intent with this package is to dramatically 
                reduce initial prep and setup. 
                <br />
                <br />
                Here's how easy it is to use...
            </p>

            <div 
                id="example-container"
                className="align-items-center justify-content-around"
            >
                <span 
                    id="example"
                    className="text-color-white font-size-2"
                >
                    <strong className="font-body-bold">.property-name</strong><em className="font-body-italic">-property-value</em>
                </span>

                <div 
                    id="example-explainer" 
                    className="p-2 box-shadow"
                >
                    <p className="font-body text-color-white font-size-2 p-0 m-0">
                        Simply apply classes to your HTML markup based on 
                        property name and values. For example, to set an 
                        element to display as flexbox row and have it’s 
                        items set to have space between them with center 
                        alignment: set your element’s class to include  
                        <strong className="font-body-bold"> .display-flex</strong>, <strong className="font-body-bold">.flex-row</strong>, <strong className="font-body-bold">.justify-content-between</strong>. 
                        and <strong className="font-body-bold">.align-items-center</strong>.
                        <br />
                        <br />
                        A full list of classes will be provided below.
                    </p>
                </div>
            </div>

            <p className="font-body text-color-white width-70 mt-4 font-size-2">
                The only aesthetically-themed classes that are provided are 
                margin and padding-related classes. <br />
                <br />
                There are 5 levels of spacing for all sides (example: px-2 
                (padding x-axis), my-2 (margin y-axis), pt-1 (padding top), 
                ml-3 (margin left), etc.) and are intended to provide quick 
                prototyping. Beyond this, any theme-related stylings will 
                have to be written on a project-basis to fit that projects 
                unique needs.
            </p>
        </section>
    );
}


// --
// Export
export default HowItWorks;